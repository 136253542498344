import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FormsModule } from '@angular/forms';
import {
  MsalModule,
  MsalService,
  MsalInterceptor,
  MsalGuard,
  MsalBroadcastService,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './shared/providers/msal-provider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiPayload } from './widgets/imops/interfaces/api-payload';
import { DatePipe } from '@angular/common';
import { NgeApiPaths } from './widgets/imops/constants/nge-api-paths';
import { ErrorNotificationService } from './error-notification.service';
import { ErrorInterceptor } from './error-interceptor';
import { NgeCdnPaths } from './widgets/imops/constants/nge-cdn-paths';

// Prime Table
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { DropdownModule } from 'primeng/dropdown';
import { UserCredentialsGuard } from './shared/services/user-credentials.guard';
import { NgxAarIonicLibraryModule } from 'aar-ionic-component-library/dist/ngx-aar-ionic-library';
import { ApimInterceptor } from './shared/services/apim.interceptor';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import dbConfig from './shared/models/db.config';

import { ApplicationStoreModule } from './store/application-store.module'
// import { applicationStoreForRoot } from './store/application-store'
import { UserManagementAccessGuard } from './pages/management/services/user-management-access-guard';
import { OrgManagementAccessGuard } from './pages/management/services/org-management-access-guard';
import { AdminManagementAccessGuard } from './pages/management/services/admin-management-access-guard';
import { DiscrepancyQueueRouteGuard } from './widgets/staar/service-modules/route-guards/discrepancy-queue-route-guard';
import { CardClosureQueueRouteGuard } from './widgets/staar/service-modules/route-guards/card-closure-queue-route-guard';
import { KickbackQueueRouteGuard } from './widgets/staar/service-modules/route-guards/kickback-queue-route-guard';
import { MyWorkCardsRouteGuard } from './widgets/staar/service-modules/route-guards/my-work-cards-route-guard';
import { InWorkProjectsRouteGuard } from './widgets/staar/service-modules/route-guards/in-work-projects-route-guard';
import { RecordsQueueRouteGuard } from './widgets/staar/service-modules/route-guards/records-queue-route-guard';
import { MroProjectManagementRouteGuard } from './widgets/staar/service-modules/route-guards/mro-project-management-route-guard';
import { WorkPackageMantainenceRouteGuard } from './widgets/staar/service-modules/route-guards/work-package-maintenance-route-guard';
import { MroCustomerRouteGuard } from './widgets/staar/service-modules/route-guards/mro-customer-route-guard';
import { RouteTransitionService } from './widgets/staar/services/route-transition.service';
import { AptyService } from './shared/services/apty.service';

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA, 
    NO_ERRORS_SCHEMA
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: false,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    MsalModule,
    BrowserAnimationsModule,
    NgxAarIonicLibraryModule,
    // Prime Table
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    ApplicationStoreModule,
  ],
  declarations: [AppComponent],
  providers: [
    AptyService,
    RouteTransitionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApimInterceptor,
      multi: true,
    },
    UserCredentialsGuard,
    UserManagementAccessGuard,
    OrgManagementAccessGuard,
    AdminManagementAccessGuard,
    DiscrepancyQueueRouteGuard,
    CardClosureQueueRouteGuard,
    KickbackQueueRouteGuard,
    MyWorkCardsRouteGuard,
    InWorkProjectsRouteGuard,
    RecordsQueueRouteGuard,
    MroProjectManagementRouteGuard,
    WorkPackageMantainenceRouteGuard,
    MroCustomerRouteGuard,
    MsalGuard,
    MsalBroadcastService,
    MsalService,
    DatePipe,
    ApiPayload,
    NgeApiPaths,
    ErrorNotificationService,
    NgeCdnPaths,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})

export class AppModule { }
